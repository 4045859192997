<template>
    <vs-sidebar position-right parent="body" default-index="1" class="sidebarx sideDrawerEditPatient" id="editPatientDetailsDrawer" spacer :click-not-close="true"
        v-model="editPatientDetailsDrawer">
        <div class="headerClass space-y-3">
          <p class="text-xl font-semibold px-4 pt-4">Edit Patient</p>
          <vs-divider></vs-divider>
        </div>
        <div class="overflow-y-auto px-4 pt-5 pb-20 flex flex-col gap-2 h-full" >
            <vs-input
                :success="!errors.first('firstName')"
                :danger="errors.first('firstName') ? true : false"
                :danger-text="errors.first('firstName')"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required|max:150'"
                name="firstName"
                data-vv-as="first name"
                label="First Name"
                class="w-full"
                v-model="patientDetails.firstName" />
            <vs-input
                :success="!errors.first('LastName')"
                :danger="errors.first('LastName') ? true : false"
                :danger-text="errors.first('LastName')"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required|max:150'"
                name="LastName"
                data-vv-as="last name"
                label="Last Name"
                class="w-full"
                v-model="patientDetails.lastName" />
            <div :class="{'input-icon-validate-success': !errors.first('dateOfBirth'), 'input-icon-validate-danger': errors.first('dateOfBirth')}">
              <label class="vs-input--label">Date of Birth</label>
              <Datepicker
                format="dd/MM/yyyy"
                v-model="dateOfBirthModel"
                :minimumView="'day'" :maximumView="'year'"
                :initialView="'year'"
                :disabled-dates="disabledDatesDOB"
                :input-class="{ 'is-true vs-input--input':!errors.first('dateOfBirth'), 'is-danger vs-input--input': errors.first('dateOfBirth')}"
              >
                <template slot="afterDateInput">
                  <span v-if="!errors.has('dateOfBirth')" class="bg-color-done input-icon-validate vs-input--icon-validate">
                    <i valiconpack="material-icons" class="vs-icon notranslate icon-scale material-icons null">done</i>
                  </span>
                  <span v-if="errors.has('dateOfBirth')" class="bg-color-error input-icon-validate vs-input--icon-validate">
                    <i valiconpack="material-icons" class="vs-icon notranslate icon-scale material-icons null">error</i>
                  </span>
                </template>
              </Datepicker>
              <span class="text-danger" style="font-size: 0.75em">{{ errors.first("dateOfBirth") && "You must be at least 18 years old" }}</span>
            </div>
            <div class="my-3">
              <label
                style="display: block"
                class="vs-input--label custom-label mb-1"
              >
                Gender
              </label>
              <vs-radio
                vs-value="male"
                vs-name="radios1"
                style="margin-right: 20px"
                v-model="patientDetails.gender"
                >Male</vs-radio
              >
              <vs-radio
                vs-value="female"
                vs-name="radios1"
                style="margin-right: 20px"
                v-model="patientDetails.gender"
                >Female</vs-radio
              >
              <vs-radio
                vs-value="other"
                vs-name="radios1"
                v-model="patientDetails.gender"
                >Other</vs-radio
              >
              <span
                v-if="errors.first('gender')"
                style="display: block"
                class="text-danger text-sm error-text no-margin-span"
                >Please select one option.</span
              >
            </div>
            <vs-textarea
                readonly
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="address"
                name="address"
                label="Address"
                class="w-full"
                v-model="patientDetails.address" />
            <vs-input
                :success="!errors.first('phoneNumber')"
                :danger="errors.first('phoneNumber') ? true : false"
                :danger-text="errors.first('phoneNumber')"
                val-icon-success="done"
                val-icon-danger="error"
                data-vv-as="phoneNumber"
                name="phoneNumber"
                label="Phone Number"
                class="w-full"
                v-model="patientDetails.contactNumber" />
            <vs-input
                :success="!errors.first('email')"
                :danger="errors.first('email') ? true : false"
                :danger-text="errors.first('email')"
                val-icon-success="done"
                val-icon-danger="error"
                v-validate="'required|email'"
                name="email"
                data-vv-as="email"
                label="Email"
                class="w-full"
                v-model="patientDetails.email" />
            <div class="my-4">
                <p class="text-sm mb-2">Allergies (optional)</p>
                <vs-button class="w-full text-sm" color="primary" type="border" @click="addAllergy()">Add Allergy</vs-button>
                <div v-for="(allergy, index) in patientDetails.allergies" :key="`allergy` + index">
                    <div class="flex gap-2 mt-2">
                        <vs-input name="allergies" data-vv-as="allergies" class="w-full"
                            v-model="patientDetails.allergies[index]" />
                        <vs-button size="medium" icon-pack="feather" icon="icon-trash" color="danger" @click="deleteAllergies(index)" />
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <p class="text-sm mb-2">Regular Medication (optional)</p>
                <vs-button class="w-full text-sm" color="primary" type="border" @click="addRegularMedication()">Add Regular
                    Medication</vs-button>
                <div v-for="(regularMedication, index) in patientDetails.regularMedications"
                    :key="`regularMedication` + index">
                    <div class="flex gap-2 mt-2">
                        <vs-input name="regularMedication" data-vv-as="regularMedication" class="w-full"
                            v-model="patientDetails.regularMedications[index]" />
                        <vs-button size="medium" icon-pack="feather" icon="icon-trash" color="danger" @click="deleteRegularMedication(index)" />
                    </div>
                </div>
            </div>
            <div class="mb-4">
                <p class="text-sm mb-2">Previous Medical Diagnosis (optional)</p>
                <vs-button class="w-full text-sm" color="primary" type="border" @click="addPreviousMedicalDiagnosis()">Add
                    Previous Medical Diagnosis</vs-button>
                <div v-for="(previousMedicalDiagnose, index) in patientDetails.previousMedicalDiagnoses"
                    :key="`previousMedicalDiagnose` + index">
                    <div class="flex gap-2 mt-2">
                        <vs-input name="previousMedicalDiagnosis" data-vv-as="previousMedicalDiagnosis" icon-no-border
                            class="w-full" v-model="patientDetails.previousMedicalDiagnoses[index]" />
                        <vs-button size="medium" icon-pack="feather" icon="icon-trash" color="danger" @click="deletePreviousMedicalDiagnosis(index)" />
                    </div>
                </div>
            </div>
        </div>
        <div class="p-4 flex flex-col gap-3" slot="footer">
          <vs-button @click="handleUpdatePatientDetails" :disabled="isUpdatingPatient">
              Update Patient
          </vs-button>
          <vs-button type="border" class="p-2" @click.prevent="closeSideDrawer">
              Cancel
          </vs-button>
        </div>
    </vs-sidebar>
</template>
<script>
import { mapActions } from 'vuex';
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
    name: "SideDrawerEditPatient",
    components: { Datepicker },
    props: {
      patient: {
        email: "",
        dateOfBirth: "",
        profileImage: "",
        allergies: [],
        regularMedications: [],
        previousMedicalDiagnoses: [],
        address: "",
        lastName: "",
        contactNumber: "",
        firstName: "",
        gender: "",
        fullName: "",
      },
    },
    data() {
      return {
        editPatientDetailsDrawer: false,
        patientDetails: {
          email: "",
          dateOfBirth: "",
          profileImage: "",
          allergies: [],
          regularMedications: [],
          previousMedicalDiagnoses: [],
          address: "",
          lastName: "",
          contactNumber: "",
          firstName: "",
          gender: "",
          fullName: "",
        },
        isUpdatingPatient: false,
        disabledDatesDOB: { from: new Date() }, 
      }
    },
    computed: {
      dateOfBirthModel: {
        get() {
          return this.patientDetails.dateOfBirth;
        },
        set(val) {
          // check if user is 18 years old or above
          const isUnderEighteen = moment(new Date(val)).format("YYYY-MM-DD") >
          moment().subtract(18, "years").format("YYYY-MM-DD")

          if (isUnderEighteen) {
            this.errors.add({
              field: "dateOfBirth",
              msg: "You must be at least 18 years old.",
            });
            this.isUpdatingPatient = true;
            return;
          } 
          this.patientDetails.dateOfBirth = val
          this.errors.remove("dateOfBirth");
          this.isUpdatingPatient = false;
        },
      },
    },
    methods: {
      ...mapActions("patient", ["updatePatientDetail"]),
      closeSideDrawer() {
        this.patientDetails = {
          email: "",
          dateOfBirth: "",
          profileImage: "",
          allergies: [],
          regularMedications: [],
          previousMedicalDiagnoses: [],
          address: "",
          lastName: "",
          contactNumber: "",
          firstName: "",
          gender: "",
          fullName: "",
        },
        this.errors.remove("dateOfBirth");
        this.isUpdatingPatient = false
        this.editPatientDetailsDrawer = false;
      },
      openSideDrawer() {
          this.editPatientDetailsDrawer = true;
      },
      addAllergy() {
          this.patientDetails.allergies.push("")
      },
      addRegularMedication() {
          this.patientDetails.regularMedications.push("")
      },
      addPreviousMedicalDiagnosis() {
          this.patientDetails.previousMedicalDiagnoses.push("")
      },
      deletePreviousMedicalDiagnosis(index) {
          this.patientDetails.previousMedicalDiagnoses.splice(index, 1)
      },
      deleteAllergies(index) {
          this.patientDetails.allergies.splice(index, 1)
      },
      deleteRegularMedication(index) {
          this.patientDetails.regularMedications.splice(index, 1)
      },
      async handleUpdatePatientDetails() {
        this.isUpdatingPatient = true;
        try {
          // check if user is 18 years old or above
          const isUnderEighteen = moment(new Date(this.patientDetails.dateOfBirth)).format("YYYY-MM-DD") >
          moment().subtract(18, "years").format("YYYY-MM-DD")

          if (isUnderEighteen) {
            this.errors.add({
              field: "dateOfBirth",
              msg: "You must be at least 18 years old.",
            });
            return;
          } 
          this.errors.remove("dateOfBirth");
          await this.updatePatientDetail({
            data: this.patientDetails,
            params: {
              patientId: this.patient._id,
              clinicId: this.patient.clinicId
            }
          })
          this.$vs.notify({
            title: "Patient updated",
            text: "Patient's details updated successfully.",
            color: "success",
          });
          this.isUpdatingPatient = false;
          this.closeSideDrawer()
          this.$emit('refetchPatientDetail')
        } catch (error) {
          this.isUpdatingPatient = false;
          this.$vs.notify({
            title: "Failed",
            text: "Failed to update patient. Try again!",
            color: "danger",
          });
        }
      },
    },
    watch: {
      editPatientDetailsDrawer(val) {
        if (val) {
          this.patientDetails = {
            email: this.patient.email,
            dateOfBirth: this.patient.dateOfBirth,
            address: this.patient.address,
            lastName: this.patient.lastName,
            contactNumber: this.patient.contactNumber,
            firstName: this.patient.firstName,
            gender: this.patient.gender,
            clinicId: this.patient.clinicId,
            allergies: [...this.patient.allergies],
            regularMedications: [...this.patient.regularMedications],
            previousMedicalDiagnoses: [...this.patient.previousMedicalDiagnoses],
          }
        }
      }
    },
}
</script>
<style lang="scss">

.sideDrawerEditPatient{
  button {
    border: 1px solid #E3DDD7;
    box-shadow: 0px 0.94px 1.88px 0px #00000014;
    font-weight: 600;
    line-height: 1.25rem;
  }
}
</style>
