<template>
  <div>
    <vs-button v-if="isEMRDoctor" @click="popupActivo=true" color="primary" >Create New Treatment</vs-button>
    <vs-popup  :active.sync="popupActivo" id="createNewPatientTreatment">
      <div class="p-5">
        <div>
          <h4 class="mb-2 mt-2">Create New Treatment</h4>
        </div>
        <div >
          <vs-select
            autocomplete
            class="selectExample"
            label="Select Clinic"
            v-model="clinicId"
            :disabled="loading"
          >
            <vs-select-item
              v-for="(item, index) in clinics"
              :key="index"
              :value="item._id"
              :text="item.clinicName"
            />
          </vs-select>
        </div>
        <div class="mt-2 flex justify-end">
          <vs-button @click="closePopup" class="p-2 mr-2" color="primary" type="border" :disabled="loading">Cancel</vs-button>
          <vs-button color="primary" type="filled" @click="createNewTreatment">Create</vs-button>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>

import { create } from 'lodash';

export default {
  props: {
    treatmentHistoryDetailRoute: {
      type: String,
    },
  },
  data() {
    return {
      popupActivo: false,
      clinicId: null,
      patientDetails: {},
      clinics: [],
      loading : false
    };
  },
  computed: {
    isEMRDoctor() {
      return JSON.parse(localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}User`)).doctorEMRClinics.length > 0;
    }
  },
  methods: {
    async getClinicsOptions() {
      try {
        const res = await this.$store.dispatch('doctor/getUserEMRclinics',
        {organizationId: localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`)}
      );
        this.clinics = res.data.data;
      } catch (error) {
        // alert(error);
        console.log(error);

        this.$vs.notify({
          title: "Error",
          text: "Error fetching clinics",
          color: "danger"
        });
      }
    },
    async getPatientDetail() {
      try {
        this.loading = true;
        const res = await this.$store.dispatch('patient/patientDetail2', this.$route.params.patientId);
          this.clinicId = res.data.data.clinicId;
          this.patientDetails = res.data.data;
        setTimeout(() => {
          this.loading = false;
        }, 500);
      } catch (error) {
        console.error(error);
      }
    },
    closePopup() {
      this.popupActivo = false;
    },
    async createNewTreatment() {
      try {
        this.$vs.loading();
        this.closePopup();
        const res = await this.$store.dispatch('doctor/createNewEMRTreatment', {
          clinicId: this.clinicId,
          patientId: this.$route.params.patientId
        });
        this.$vs.loading.close();
        this.$router.push({
          name: this.treatmentHistoryDetailRoute,
          params: {
            patientId: this.$route.params.patientId,
            checkinId: res.data.data.patientCheckinId,
          },
          query: {
            backRoute: this.$route.name,
            appointmentId: res.data.data._id
          }
        });
      } catch (error) {
        console.log(error)
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Error",
          text: "Error creating treatment",
          color: "danger"
        });
      }
    },
  },
  watch:{
    clinics: function (newVal) {
      if (newVal.length > 0 && newVal.filter(clinic => `${clinic._id}` === `${this.clinicId}`).length === 0) {
        this.clinicId = newVal[0]._id;
      }
    },
    clinicId: function (newVal, oldVal) {
      if  (newVal !== oldVal && this.clinics.filter(clinic => `${clinic._id}` === `${newVal}`).length === 0) {
        this.clinicId = this.clinics[0]._id;
      }
      else{
        this.clinicId = newVal;
      }
    }
  },
  mounted() {
    this.getPatientDetail();
    this.getClinicsOptions();
  }
};
</script>
