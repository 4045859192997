<template>
  <div>
    <vs-card>
      <div style="padding: 0.9rem 0.8rem 0" class="flex flex-wrap w-full justify-between">
        <h4>Patient Details</h4>
        <button class="underline text-base border-none bg-white shadow-none cursor-pointer mt-1" color="primary"
                type="flat" @click="editPatientDetailsDrawer()"> Edit </button>
      </div>

      <div class="flex flex-wrap">
        <div class="flex w-full lg:w-1/3">
          <div class="w-full m-5">
            <label for="firstName">First Name</label>
            <p id="firstName">{{ firstName }}</p>
          </div>
        </div>

        <div class="flex w-full lg:w-1/3">
          <div class="w-full m-5">
            <label for="lastName">Last Name</label>
            <p id="lastName">{{ lastName }}</p>
          </div>
        </div>

        <div class="flex w-full lg:w-1/3">
          <div class="w-full m-5">
            <label for="dateOfBirth">DOB</label>
            <p id="dateOfBirth">
              {{ moment(dateOfBirth).format("DD/MM/YYYY") }}
            </p>
          </div>
        </div>

        <div class="flex w-full lg:w-1/3">
          <div class="w-full m-5">
            <label for="email">Email</label>
            <p id="email">{{ email }}</p>
          </div>
        </div>

        <div class="flex w-full lg:w-1/3">
          <div class="w-full m-5">
            <label for="gender">Gender</label>
            <p id="lastName">{{ gender | capitalize }}</p>
          </div>
        </div>

        <div class="flex w-full lg:w-1/3">
          <div class="w-full m-5">
            <label for="address">Address</label>
            <p id="address">{{ address }}</p>
          </div>
        </div>

        <div class="flex w-full lg:w-1/3">
          <div class="w-full m-5">
            <label for="contactNumber">Contact Number</label>
            <p id="contactNumber">{{ contactNumber }}</p>
          </div>
        </div>

        <!-- <vs-col vs-type="flex" vs-justify="left" vs-align="left" vs-w="4">
          <div class="w-full m-5">
            <label for="status">Status</label>
            <p id="status">{{status}}</p>
          </div>
        </vs-col>-->
      </div>
    </vs-card>

    <MedicalDetail
      v-if="check_has_permission('viewPatientMedicalDetail')"
      :allergies="allergies"
      :regularMedications="regularMedications"
      :previousMedicalDiagnoses="previousMedicalDiagnoses"
    />
    <SideDrawerEditPatient
      :patient=patientData
      @refetchPatientDetail=rerfreshData
      ref="sideDrawerEditPatient"
    />
  </div>
</template>
<script>
import { mapActions } from "vuex";
import MedicalDetail from "./MedicalDetail";
import moment from "moment";
import SideDrawerEditPatient from '@/views/general-component/treatment-history/SideDrawerEditPatient.vue'


export default {
  name: "PatientDetail",
  components: {
    MedicalDetail,
    SideDrawerEditPatient
  },
  props: {
    backRoute: {
      type: String,
      default: "PatientManagement",
    },
  },
  data() {
    return {
      email: "",
      dateOfBirth: "",
      profileImage: "",
      allergies: [],
      regularMedications: [],
      previousMedicalDiagnoses: [],
      status: "",
      address: "",
      lastName: "",
      contactNumber: "",
      firstName: "",
      gender: "",
      patientData:{
        email: "",
        dateOfBirth: "",
        profileImage: "",
        allergies: [],
        regularMedications: [],
        previousMedicalDiagnoses: [],
        status: "",
        address: "",
        lastName: "",
        contactNumber: "",
        firstName: "",
        gender: "",
      }
    };
  },
  methods: {
    ...mapActions("patient", ["patientDetail2"]),
    moment(date) {
      return moment(date);
    },
    editPatientDetailsDrawer() {
      this.$refs.sideDrawerEditPatient.openSideDrawer();
    },
    rerfreshData() {
      this.$vs.loading();
      this.getPatientDetail(this.$route.params.patientId);
      setTimeout(() => {
        this.$vs.loading.close();
      }, 500);
    },
    async getPatientDetail(patientId) {
      await this.patientDetail2(patientId)
        .then((res) => {
          if (res.data.data.permissions) {
            this.$store.commit(
              "UPDATE_USER_PERMISSION",
              res.data.data.permissions
            );
          }
          const patientData = res.data.data;
          this.patientData = patientData;
          this.dateOfBirth = patientData.dateOfBirth;
          this.profileImage = patientData.profileImage;
          this.allergies = patientData.allergies;
          this.regularMedications = patientData.regularMedications;
          this.previousMedicalDiagnoses = patientData.previousMedicalDiagnoses;
          this.status = patientData.status;
          this.address = patientData.address;
          this.lastName = patientData.lastName;
          this.contactNumber = patientData.contactNumber;
          this.firstName = patientData.firstName;
          this.gender = patientData.gender;
          this.email = patientData.email;
        })
        .catch((err) => console.log("*******************", err));
    },
  },
  async created() {
    let patientId = this.$route.params.patientId;
    await this.getPatientDetail(patientId);
  },
};
</script>

<style>
p {
  font-size: 15px;
  font-weight: 500;
}
.sidebarx .vs-sidebar-primary {
  max-width: none;
}

@media (min-width: 768px) {
  .sidebarx .vs-sidebar-primary {
    max-width: 350px;
  }
}
.vs-sidebar--background {
  z-index: 52000 !important;
}

.vs-sidebar.vs-sidebar-position-right {
  z-index: 53000;
}

</style>
