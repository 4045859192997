<template>
  <div>
    <vx-card title="Treatment History" class="mt-base">
      <div class="mb-2 flex flex-no-wrap lg:hidden">
        <div class="w-full pr-1">
          <vs-input
            icon="search"
            style="width: 100%"
            placeholder="Search"
            v-model="searchInputString"
          />
        </div>
        <div class="w-24 pl-1">
          <vs-select
            width="100%"
            placeholder="10"
            autocomplete
            v-model="dataTableParams.limit"
          >
            <vs-select-item
              :key="index"
              :value="item.value"
              :text="item.text"
              v-for="(item, index) in limitOptions"
            />
          </vs-select>
        </div>
        <div>
          <CreatePatientTreatment
            :treatmentHistoryDetailRoute="treatmentHistoryDetailRoute"
          />
        </div>
      </div>
      <vs-table
        class="lg:hidden"
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="data"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        :noDataText="noDataText"
      >
        <template slot="thead">
          <vs-th sort-key="appointmentDate">Date</vs-th>
          <vs-th sort-key="clinicName">Clinic</vs-th>
          <vs-th sort-key="nurse">Nurse</vs-th>
          <vs-th sort-key="doctor">Doctor</vs-th>
          <vs-th sort-key="treatment">Treatment</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th>Actions</vs-th
          >
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].appointmentDate">{{
              data[indextr].appointmentDate | date_formatter
            }}</vs-td>
            <vs-td :data="data[indextr].clinicName">{{
              data[indextr].clinicName
            }}</vs-td>
            <vs-td :data="data[indextr].nurse">{{ data[indextr].nurse }}</vs-td>
            <vs-td :data="data[indextr].doctor">{{
              data[indextr].doctor
            }}</vs-td>
            <vs-td :data="data[indextr].treatments">{{
              data[indextr].treatments
            }}</vs-td>
            <vs-td>{{ data[indextr].status }}</vs-td>
            <vs-td :data="data[indextr]._id">
              <vs-button
                title="View Detail"
                type="border"
                size="small"
                @click="viewPatientDetail(data[indextr]._id, tr.appointmentId)"
                icon-pack="feather"
                icon="icon-eye"
                class="mr-2"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <vs-table
        class="hidden lg:block"
        ref="table"
        :sst="true"
        :total="totalDocs"
        :data="data"
        @search="handleSearch"
        @change-page="handleChangePage"
        @sort="handleSort"
        :max-items="dataTableParams.limit"
        search
        :noDataText="noDataText"
      >
        <div
          slot="header"
          class="flex flex-wrap-reverse flex-grow justify-between"
        >
          <div style="float: left">
            <vs-select
              placeholder="10"
              vs-multiple
              autocomplete
              v-model="dataTableParams.limit"
              class="mr-6"
            >
              <vs-select-item
                :key="index"
                :value="item.value"
                :text="item.text"
                v-for="(item, index) in limitOptions"
                :clearable="false"
              />
            </vs-select>
          </div>
          <div class="mx-4 flex items-center">
            <CreatePatientTreatment
              :treatmentHistoryDetailRoute="treatmentHistoryDetailRoute"
            />
          </div>
        </div>

        <template slot="thead">
          <vs-th sort-key="appointmentDate">Date</vs-th>
          <vs-th sort-key="clinicName">Clinic</vs-th>
          <vs-th sort-key="nurse">Nurse</vs-th>
          <vs-th sort-key="doctor">Doctor</vs-th>
          <vs-th sort-key="treatment">Treatment</vs-th>
          <vs-th sort-key="status">Status</vs-th>
          <vs-th>Actions</vs-th
          >
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
            <vs-td :data="data[indextr].appointmentDate">{{
              data[indextr].appointmentDate | date_formatter
            }}</vs-td>
            <vs-td :data="data[indextr].clinicName">{{
              data[indextr].clinicName
            }}</vs-td>
            <vs-td :data="data[indextr].nurse">{{ data[indextr].nurse }}</vs-td>
            <vs-td :data="data[indextr].doctor">{{
              data[indextr].doctor
            }}</vs-td>
            <vs-td :data="data[indextr].treatments">{{
              data[indextr].treatments
            }}</vs-td>
            <vs-td>
              <span v-if="data[indextr].treatmentOwnerDetail.userType === 'doctor'">-</span>
              <span v-else>{{ data[indextr].status }}</span>
            </vs-td>
            <vs-td :data="data[indextr]._id">
              <vs-button
                title="View Detail"
                type="border"
                size="small"
                @click="viewPatientDetail(data[indextr]._id, tr.appointmentId)"
                icon-pack="feather"
                icon="icon-eye"
                class="mr-2"
              ></vs-button>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
      <div
        class="
          m-2
          flex
          sm:justify-between
          justify-center
          items-center
          flex-wrap
        "
        style="height: 35px padding-bottom: 30px"
      >
        <span class="mr-2">
          {{
            dataTableParams.page * dataTableParams.limit -
            (dataTableParams.limit - 1)
          }}
          -
          {{
            totalDocs - dataTableParams.page * dataTableParams.limit > 0
              ? dataTableParams.page * dataTableParams.limit
              : totalDocs
          }}
          of {{ totalDocs }}
        </span>
        <div class="pagination-div" v-if="serverResponded">
          <paginate
            :page-count="totalPage"
            :click-handler="handleChangePage"
            class="pagination"
            :page-range="5"
            :prevText="'<'"
            :nextText="'>'"
          ></paginate>
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import vSelect from "vue-select";
import CreatePatientTreatment from "@/views/general-component/treatment-history/CreatePatientTreatment";

export default {
  name: "PatientTreatmentList",
  components: {
    "v-select": vSelect,
    CreatePatientTreatment
  },
  props: {
    treatmentHistoryDetailRoute: {
      type: String,
      default: "FranchisePatientTreatmentDetail",
    },
  },
  data() {
    return {
      noDataText: "Loading...",
      data: [],
      currentPage: 1,
      searchInputString: null,
      dataTableParams: {
        search: "",
        sort: "createdAt",
        dir: "desc",
        page: 1,
        limit: 25,
      },
      limitOptions: [
        { text: "5", value: 5 },
        { text: "10", value: 10 },
        { text: "25", value: 25 },
        { text: "50", value: 50 },
        { text: "100", value: 100 },
      ],
      patientId: null,
      totalDocs: 0,
      serverResponded: false,
      orgId: null,
    };
  },
  methods: {
    ...mapActions("patient", ["patientTreatmentList2"]),
    getPatientTreatmentHistory() {
      this.dataTableParams.patientId = this.patientId;
      this.patientTreatmentList2(this.dataTableParams)
        .then((response) => {
          this.data = response.data.data.docs;
          this.totalDocs = response.data.data.pagination[0].total;
          this.page = response.data.data.pagination[0].page;
          this.noDataText = "No Treatments history found";
          this.serverResponded = true;
        })
        .catch((err) => console.log("##############", err));
    },
    handleSearch(searching) {
      this.serverResponded = false;
      this.dataTableParams.search = searching;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getPatientTreatmentHistory();
    },
    handleChangePage(page) {
      this.dataTableParams.page = page;
      this.getPatientTreatmentHistory("changePage");
    },
    handleSort(key, active) {
      this.dataTableParams.sort = key;
      this.dataTableParams.dir = active;
      this.dataTableParams.page = 1;
      this.$refs.table.currentx = 1;
      this.getPatientTreatmentHistory();
    },
    viewPatientDetail(patientCheckinId, appointmentId) {
      this.$router.push({
        name: this.treatmentHistoryDetailRoute,
        params: {
          id: this.$route.params.patientId,
          checkinId: patientCheckinId,
          org: this.orgId
        },
        query: {
          backRoute: this.$route.name,
          appointmentId
        }
      });
    },
  },
  watch: {
    searchInputString: function (newVal, oldVal) {
      this.handleSearch(newVal);
    },
    "dataTableParams.page": function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.dataTableParams.page = newVal;
        this.getPatientTreatmentHistory();
      }
    },
    "dataTableParams.limit": function (newlimit, oldLimit) {
      if (newlimit !== oldLimit) {
        this.serverResponded = false;
        this.dataTableParams.page = 1;
        this.dataTableParams.limit = newlimit;
        this.getPatientTreatmentHistory();
      }
    },
  },
  computed: {
    totalPage: function () {
      return this.totalDocs / this.dataTableParams.limit >
        parseInt(this.totalDocs / this.dataTableParams.limit)
        ? parseInt(this.totalDocs / this.dataTableParams.limit) + 1
        : parseInt(this.totalDocs / this.dataTableParams.limit);
    },
  },
  created() {
    this.patientId = this.$route.params.patientId;
    this.orgId = localStorage.getItem(`${process.env.VUE_APP_ACCESS_TOKEN_NAME}OrganizationId`);
    this.getPatientTreatmentHistory();
  },
};
</script>
